import axios from 'axios';
import router from "@/router";
 // import qs from 'qs'

const result = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' })
  // },
})

// 添加请求拦截器
result.interceptors.request.use(function (config) {
  if (localStorage.getItem('token')){
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    config.headers.xcode = localStorage.getItem('admin_code')
  }
  config.headers.channel = `public_account`
  // 返回设置
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response 拦截器
result.interceptors.response.use(function (response) {
  // return response.data
  switch (response.data.code) {
    case 401:
      // 401为未登录状态，一律跳转到登录页
      router.replace({
        name: 'login'
      })
      break;
    default:
      return response.data;
  }
}, function (error) {
  return Promise.reject(error);
});


export default result
