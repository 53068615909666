import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import request from './utils/request';
import 'vant/lib/index.css';
import Vant from 'vant';
import { Toast } from 'vant';
// import VConsole from 'vconsole'
// const vconsole = new VConsole()
const app = createApp(App);
// 加载状态
app.use(router).use(Vant).use(Vant.Lazyload).use(Toast).mount('#app');
app.config.globalProperties.$request = request;
