<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@import "@/assets/style/default.scss";
.app {
  .anchorBL {
    display: none !important;
  }
}
</style>
