import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    component: () => import('@/views'),
    meta: {
      title: '主页',
    },
    redirect: '/shMap',
    children: [
      {
        path: '/shMap',
        name: 'shMap',
        component: () => import('@/views/shMap.vue'),
        meta: {
          title: '手绘地图',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/shLine',
        name: 'shLine',
        component: () => import('@/views/shLine.vue'),
        meta: {
          title: '手绘地图',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/shLineImg',
        name: 'shLineImg',
        component: () => import('@/views/shLineImg.vue'),
        meta: {
          title: '手绘地图',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/shLineImg2',
        name: 'shLineImg2',
        component: () => import('@/views/shLineImg2.vue'),
        meta: {
          title: '手绘地图',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/detail.vue'),
        meta: {
          title: '景区详情',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/explainPersionList',
        name: 'explainPersionList',
        component: () => import('@/views/explainPersionList.vue'),
        meta: {
          title: '讲解员列表',
          auth: {
            required: false,
          },
        },
      },
      {
        path: '/payLog',
        name: 'payLog',
        component: () => import('@/views/payLog.vue'),
        meta: {
          title: '打赏记录',
          auth: {
            required: false,
          },
        },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
